import { IApplicationSubmission, IPolicy } from "../../db/models"
import fetchPartnerService from "../../service/fetchPartnerService"

async function getApplicationSubmissionsWithAttribute(
  startDate: string,
  endDate: string,
  status: string
) {
  const res = await fetchPartnerService(
    "POST",
    {
      startDate,
      endDate,
      status,
    },
    "general/getApplicationSubmissionsWithAttribute"
  )
  return res.applicationSubmissions as IApplicationSubmission[]
}

async function getBusinessesWithApplicationSubmissionsWithAttribute(
  licenseId: string,
  startDate: string,
  endDate: string,
  status: string
): Promise<number> {
  const res = await fetchPartnerService(
    "POST",
    {
      licenseId,
      startDate,
      endDate,
      status,
    },
    "general/getBusinessesWithApplicationSubmissionsWithAttribute"
  )
  return res.businessesWithApplicationSubmissions as number
}

async function getTotalPremiumForDates(
  startDate: string,
  endDate: string,
  licenseId: string
): Promise<number> {
  const res = await fetchPartnerService(
    "POST",
    {
      startDate,
      endDate,
      licenseId,
    },
    "general/getTotalPremiumForDates"
  )
  return res.totalPremium as number
}

async function getPoliciesForPartner(startDate: string, endDate: string) {
  const res = await fetchPartnerService(
    "POST",
    {
      startDate,
      endDate,
    },
    "general/getPoliciesForPartner"
  )
  return res as {
    policies: IPolicy[]
    endorsements: { [license: string]: number }
  }
}

const getTotalPartnerRevShare = async (
  licenseId: string,
  startDate: string,
  endDate: string
): Promise<{
  totalPartnerRevShare: number
  [date: string]: number
}> => {
  const res = await fetchPartnerService(
    "POST",
    {
      licenseId,
      startDate,
      endDate,
    },
    "general/getTotalPartnerRevShare"
  )
  return res.totalPartnerRevShare as {
    totalPartnerRevShare: number
    [date: string]: number
  }
}

export {
  getApplicationSubmissionsWithAttribute,
  getBusinessesWithApplicationSubmissionsWithAttribute,
  getTotalPremiumForDates,
  getPoliciesForPartner,
  getTotalPartnerRevShare,
}
